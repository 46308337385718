// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Result } from "postcss";
import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    const el = document.createElement("span");
    el.setAttribute("class", "count");
    const rest = this.element.attributes["data-maxlength"].value - this.element.value.length;
    el.innerHTML = "Zeichen verbleibend: " + rest;
    this.element.parentNode.insertBefore(el, this.element.nextSibling);
  }

  recalc() {
    this.element.value = this.element.value.substring(0, this.element.attributes["data-maxlength"].value);
    const el = document.createElement("span");
    el.setAttribute("class", "count");
    const rest = this.element.attributes["data-maxlength"].value - this.element.value.length;
    el.innerHTML = "Zeichen verbleibend: " + rest;
    this.element.nextSibling.remove();
    this.element.parentNode.insertBefore(el, this.element.nextSibling);
  }
}
